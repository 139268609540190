<template>
  <b-modal
    id="modal-merchant-update-admin"
    ref="modal"
    v-model="open"
    size="lg"
    no-close-on-backdrop
    hide-footer
    :title="`UPDATE MERCHANT ${type.toUpperCase()}`"
  >
    <b-overlay
      :show="modalLoading"
      blur="10px"
      opacity="0.30"
      rounded="sm"
      variant="light"
    >
      <ValidationObserver
        ref="updateMerchantAdminForm"
        v-slot="{ handleSubmit }"
        slim
      >
        <b-form
          class="my-8"
          @submit.prevent="handleSubmit(onClickUpdateMerchantAdmin)"
        >
          <b-row>
            <b-col cols="6">
              <b-form-group
                class="required"
                label="Username"
                label-for="h-username"
              >
                <ValidationProvider
                  #default="{ errors }"
                  name="username"
                  rules="required"
                >
                  <b-form-input
                    id="username"
                    v-model="form.username"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Enter the username"
                    type="text"
                    autocomplete="nope"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group
                class="required"
                label="First Name"
                label-for="h-firstName"
              >
                <ValidationProvider
                  #default="{ errors }"
                  name="firstName"
                  rules="required"
                >
                  <b-form-input
                    id="firstName"
                    v-model="form.firstName"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Enter the first name"
                    type="text"
                    autocomplete="nope"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="6">
              <b-form-group
                class="required"
                label="Last Name"
                label-for="h-lastName"
              >
                <ValidationProvider
                  #default="{ errors }"
                  name="lastName"
                  rules="required"
                >
                  <b-form-input
                    id="lastName"
                    v-model="form.lastName"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Enter the last name"
                    type="text"
                    autocomplete="nope"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group
                class="required"
                label="Email"
                label-for="h-userEmail"
              >
                <ValidationProvider
                  #default="{ errors }"
                  name="userEmail"
                  rules="required"
                >
                  <b-form-input
                    id="userEmail"
                    v-model="form.userEmail"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Enter the admin email"
                    type="text"
                    autocomplete="nope"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="6">
              <b-form-group
                class="required"
                label="Contact No."
                label-for="h-userContactNo"
              >
                <ValidationProvider
                  #default="{ errors }"
                  name="userContactNo"
                  rules="required"
                >
                  <b-form-input
                    id="userContactNo"
                    v-model="form.userContactNo"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Enter the contactNo"
                    type="text"
                    autocomplete="nope"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group
                label="Status"
                label-for="h-status"
                style="margin-top:7px;"
              >
                <ValidationProvider
                  #default="{ errors }"
                  name="status"
                  rules=""
                >
                  <b-form-group>
                    <b-form-checkbox
                      id="status"
                      v-model="form.status"
                      :value="true"
                      :unchecked-value="false"
                    >
                      Active
                    </b-form-checkbox>
                  </b-form-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>
            </b-col>
            <b-col
              v-if="type !== 'user'"
              cols="12"
            >
              <b-form-group
                label="Password"
                label-for="password"
              >
                <b-input-group>
                  <b-form-input
                    id="password"
                    v-model="new_password"
                    placeholder="Enter new password"
                    :type="showPassword ? 'text' : 'password'"
                  />
                  <b-input-group-append>
                    <b-button
                      v-b-tooltip.hover.right="'Show password'"
                      variant="outline-primary"
                      @click="togglePasswordVisibility"
                    >
                      <feather-icon :icon="showPassword ? 'EyeOffIcon' : 'EyeIcon'" />
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>

          <!-- submit and reset -->
          <b-row>
            <b-col
              offset-md="4"
            >
              <div>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  class="mr-1 custom-button-color"
                >
                  Submit
                </b-button>
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  type="reset"
                  variant="outline-secondary"
                >
                  Clear
                </b-button>
              </div>
            </b-col>
          </b-row>
        </b-form>
      </ValidationObserver>
    </b-overlay>
  </b-modal>
</template>

<script>
import {
  BButton,
  BModal,
  BCol,
  BFormGroup,
  BForm,
  BFormInput,
  BRow,
  BOverlay,
  VBModal,
  BFormCheckbox,
  BInputGroupAppend,
  BInputGroup,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import SuccessMessage from '@core/mixins/SuccessMessage'
import ErrorMessage from '@core/mixins/ErrorMessage'
import { RepositoryFactory } from '@/repository/RepositoryFactory'

const MerchantRepository = RepositoryFactory.get('merchant')

function initialState() {
  return {
    open: false,
    id: null,
    type: null,
    form: {},
    modalLoading: false,
    loading: false,
    new_password: '',
    userEmail: '',
    showPassword: false,
  }
}

export default {
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  components: {
    BButton,
    BModal,
    BCol,
    BFormGroup,
    BForm,
    BFormInput,
    BRow,
    BOverlay,
    BFormCheckbox,
    BInputGroup,
    BInputGroupAppend,
  },
  mixins: [SuccessMessage, ErrorMessage],
  data() {
    return initialState()
  },
  methods: {
    async openModal(merchantId, adminId, type) {
      await Object.assign(this.$data, initialState())
      this.open = true
      this.merchantId = merchantId
      this.adminId = adminId
      this.type = type
      await this.fetchMerchantAdminDetails()
      this.modalLoading = false
    },
    async fetchMerchantAdminDetails() {
      try {
        const { data } = (await MerchantRepository.getMerchantUserDetails(this.merchantId, this.adminId)).data
        this.userEmail = data.email
        this.form = {
          merchant_business_id: data.merchant_business[0].id,
          firstName: data.first_name,
          lastName: data.last_name,
          userEmail: data.email,
          username: data.user_name,
          userContactNo: data.phone,
          roleId: data.role.id,
          status: data.is_active,
        }
      } catch (e) {
        this.convertAndNotifyError(e)
      }
    },
    async onClickClose() {
      this.open = false
    },

    async onClickUpdateMerchantAdmin() {
      this.modalLoading = true
      let payload = {}
      try {
        payload = {
          merchant_business_id: this.form.merchant_business_id,
          role_id: this.form.roleId,
          user_details: {
            first_name: this.form.firstName,
            last_name: this.form.lastName,
            email: this.form.userEmail,
            user_name: this.form.username,
            phone: this.form.userContactNo,
            is_active: this.form.status,
            new_password: this.new_password,
          },
        }
        if (this.type === 'user') delete payload.user_details.new_password
        const res = await MerchantRepository.updateMerchantUser(this.merchantId, this.adminId, payload)
        if (res.status === 200) {
          if (this.new_password) {
            const passwordPayload = {
              password: this.new_password,
            }
            const pwdRes = await MerchantRepository.merchantUserResetPassword(this.merchantId, this.adminId, passwordPayload)
            if (pwdRes.status !== 200) {
              this.showErrorMessage('Failed to update admin password !!')
            }
          }
          this.showSuccessMessage('Merchant admin updated successfully')
          this.open = false
          this.$parent.onClickRefresh()
        } else {
          this.showErrorMessage('Failed to update admin !!')
        }
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.modalLoading = false
    },
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword
    },
  },
}
</script>
<style scoped>
@import "../../../node_modules/@syncfusion/ej2-base/styles/fabric.css";
@import "../../../node_modules/@syncfusion/ej2-vue-navigations/styles/fabric.css";
@import "../../../node_modules/@syncfusion/ej2-inputs/styles/fabric.css";
@import "../../../node_modules/@syncfusion/ej2-buttons/styles/fabric.css";
.custom-button-color {
  background-color: #4186f5 !important;
  border-color: #4186f5 !important;
}
</style>
<style lang="scss">
@import '@core/scss/vue/required.scss';
</style>
