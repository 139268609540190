<template>
  <div>
    <b-overlay
      :show="loading"
      blur="10px"
      opacity="0.30"
      rounded="sm"
      variant="light"
    >
      <ValidationObserver
        v-slot="{ handleSubmit }"
        slim
      >
        <b-form
          class="my-8 d-flex flex-wrap"
          @submit.prevent="handleSubmit(onClickUpdateMerchantBusinessGeneralDetails)"
        >
          <b-col cols="12">
            <b-form-group
              class="required"
              label="Business Name"
              label-for="h-businessName"
              style="width: 45%"
            >
              <ValidationProvider
                #default="{ errors }"
                name="businessName"
                rules="required"
              >
                <b-form-input
                  id="businessName"
                  v-model="form.businessName"
                  :state="errors.length > 0 ? false:null"
                  placeholder="Enter the business name"
                  type="text"
                  autocomplete="nope"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>
            </b-form-group>
          </b-col>

          <!-- submit and reset -->
          <b-col
            class="d-flex justify-content-end"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              class="mr-1"
            >
              Submit
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="reset"
              variant="outline-secondary"
            >
              Clear
            </b-button>
          </b-col>
        </b-form>
      </ValidationObserver>
    </b-overlay>
  </div>
</template>

<script>
import {
  BButton,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  VBModal,
  BOverlay,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import SuccessMessage from '@core/mixins/SuccessMessage'
import ErrorMessage from '@core/mixins/ErrorMessage'
import { RepositoryFactory } from '@/repository/RepositoryFactory'

const MerchantRepository = RepositoryFactory.get('merchant')

function initialState() {
  return {
    open: false,
    form: {},
    loading: false,
    setPrimary: false,
  }
}

export default {
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  components: {
    BButton,
    BCol,
    BFormGroup,
    BForm,
    BFormInput,
    BOverlay,
  },
  mixins: [SuccessMessage, ErrorMessage],
  data() {
    return initialState()
  },
  mounted() {
    // Set the initial number of items
    this.fetchMerchantBusinessGeneralDetails()
  },
  methods: {
    onClickRefresh() {
      this.fetchMerchantBusinessGeneralDetails()
    },
    async fetchMerchantBusinessGeneralDetails() {
      try {
        const { data } = (await MerchantRepository.getMerchantBusinessDetails(this.$route.params.id, this.$route.params.businessId)).data
        this.form = {
          businessName: data.business_name,
        }
        this.setPrimary = data.is_default
      } catch (e) {
        this.convertAndNotifyError(e)
      }
    },

    async onClickUpdateMerchantBusinessGeneralDetails() {
      this.loading = true
      try {
        const payload = {
          business_name: this.form.businessName,
        }

        await MerchantRepository.updateMerchantBusiness(this.$route.params.id, this.$route.params.businessId, payload)
        this.showSuccessMessage('Merchant business name updated successfully')
        this.open = false
        this.$parent.onClickRefresh()
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.loading = false
    },
  },
}
</script>
<style scoped>
@import "../../../node_modules/@syncfusion/ej2-base/styles/fabric.css";
@import "../../../node_modules/@syncfusion/ej2-vue-navigations/styles/fabric.css";
@import "../../../node_modules/@syncfusion/ej2-inputs/styles/fabric.css";
@import "../../../node_modules/@syncfusion/ej2-buttons/styles/fabric.css";
</style>
<style lang="scss">
@import '@core/scss/vue/required.scss';
</style>
