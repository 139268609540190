<template>
  <b-modal
    id="modal-merchant-business-address-update"
    ref="modal"
    v-model="open"
    size="lg"
    no-close-on-backdrop
    hide-footer
    title="UPDATE MERCHANT BUSINESS ADDRESS"
  >
    <b-overlay
      :show="modalLoading"
      blur="10px"
      opacity="0.30"
      rounded="sm"
      variant="light"
    >
      <ValidationObserver
        ref="updateMerchantBusinessAddressForm"
        v-slot="{ handleSubmit }"
        slim
      >
        <b-form
          class="my-8"
          @submit.prevent="handleSubmit(onClickUpdateMerchantBusinessAddress)"
        >
          <b-row>
            <b-col cols="6">
              <b-form-group
                class="required"
                label="Address Type"
                label-for="h-addressType"
              >
                <ValidationProvider
                  #default="{ errors }"
                  name="addressType"
                  rules="required"
                >
                  <v-select
                    v-model="form.addressType"
                    :options="addressTypeOptions"
                    :reduce="option => option.id"
                    label="name"
                    autocomplete="nope"
                    placeholder="Select the address type"
                  >
                    <template v-slot:option="option">
                      {{ option.name }}
                    </template>
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="6">
              <b-form-group
                class="required"
                label="Address Line 1"
                label-for="h-addressLine1"
              >
                <ValidationProvider
                  #default="{ errors }"
                  name="addressLine1"
                  rules="required"
                >
                  <b-form-input
                    id="addressLine1"
                    v-model="form.addressLine1"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Enter the address line 1"
                    type="text"
                    autocomplete="nope"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group
                label="Address Line 2"
                label-for="h-addressLine2"
              >
                <ValidationProvider
                  #default="{ errors }"
                  name="addressLine2"
                  rules=""
                >
                  <b-form-input
                    id="addressLine2"
                    v-model="form.addressLine2"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Enter the address line 2"
                    type="text"
                    autocomplete="nope"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="6">
              <b-form-group
                label="Address Line 3"
                label-for="h-addressLine3"
              >
                <ValidationProvider
                  #default="{ errors }"
                  name="addressLine3"
                  rules=""
                >
                  <b-form-input
                    id="addressLine3"
                    v-model="form.addressLine3"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Enter the address line 3"
                    type="text"
                    autocomplete="nope"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group
                class="required"
                label="Postal /Zip Code"
                label-for="h-postalCode"
              >
                <ValidationProvider
                  #default="{ errors }"
                  name="postalCode"
                  rules="required"
                >
                  <b-form-input
                    id="postalCode"
                    v-model="form.postalCode"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Enter the postal /zip Code"
                    type="text"
                    autocomplete="nope"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="6">
              <b-form-group
                class="required"
                label="Country"
                label-for="h-country"
              >
                <ValidationProvider
                  #default="{ errors }"
                  name="country"
                  rules="required"
                >
                  <v-select
                    v-model="form.country"
                    :options="countryOptions"
                    :reduce="option => option.id"
                    label="name"
                    autocomplete="nope"
                    placeholder="Select the country"
                    @input="fetchStateList()"
                  >
                    <template v-slot:option="option">
                      {{ option.name }}
                    </template>
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group
                class="required"
                label="State"
                label-for="h-state"
              >
                <ValidationProvider
                  #default="{ errors }"
                  name="state"
                  rules="required"
                >
                  <v-select
                    v-model="form.state"
                    :options="stateOptions"
                    :reduce="option => option.id"
                    label="name"
                    autocomplete="nope"
                    placeholder="Select the state"
                    @input="{
                      delete form.city
                      fetchCityList()}"
                  >
                    <template v-slot:option="option">
                      {{ option.name }}
                    </template>
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="6">
              <b-form-group
                class="required"
                label="City"
                label-for="h-city"
              >
                <ValidationProvider
                  #default="{ errors }"
                  name="city"
                  rules="required"
                >
                  <v-select
                    v-model="form.city"
                    :options="cityOptions"
                    :reduce="option => option.id"
                    label="name"
                    autocomplete="nope"
                    placeholder="Select the city"
                    @search="(search, loading) => {
                      loading(true)
                      fetchCityList(search).then(() => loading(false))
                    }"
                  >
                    <template v-slot:option="option">
                      {{ option.name }}
                    </template>
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-checkbox
                v-model="form.is_default"
                :value="true"
                :unchecked-value="false"
                style="margin-top:27px"
              >
                Is Default Address
              </b-form-checkbox>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="6">
              <b-form-group
                label="Contact No."
                label-for="h-contactNo"
              >
                <ValidationProvider
                  #default="{ errors }"
                  name="contactNo"
                  rules="required"
                >
                  <b-form-input
                    id="contactNo"
                    v-model="form.contactNo"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Enter the contact no."
                    type="text"
                    autocomplete="nope"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group
                class="required"
                label="Email"
                label-for="h-email"
              >
                <ValidationProvider
                  #default="{ errors }"
                  name="email"
                  rules="required"
                >
                  <b-form-input
                    id="email"
                    v-model="form.email"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Enter the email"
                    type="text"
                    autocomplete="nope"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>
            </b-col>
          </b-row>
          <!--          <b-row>-->
          <!--            <b-col cols="12">-->
          <!--              <b-form-group-->
          <!--                label="Ref No."-->
          <!--                label-for="h-refNo"-->
          <!--                label-cols-md="4"-->
          <!--              >-->
          <!--                <ValidationProvider-->
          <!--                  #default="{ errors }"-->
          <!--                  name="refNo"-->
          <!--                  rules="required"-->
          <!--                >-->
          <!--                  <b-form-input-->
          <!--                    id="refNo"-->
          <!--                    v-model="form.refNo"-->
          <!--                    :state="errors.length > 0 ? false:null"-->
          <!--                    placeholder="Enter the ref no."-->
          <!--                    type="text"-->
          <!--                    class="col-md-6"-->
          <!--                  />-->
          <!--                  <small class="text-danger">{{ errors[0] }}</small>-->
          <!--                </ValidationProvider>-->
          <!--              </b-form-group>-->
          <!--            </b-col>-->
          <!--          </b-row>-->

          <!-- submit and reset -->
          <b-row>
            <b-col
              v-can="'edit-merchant-address'"
              offset-md="4"
            >
              <div>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  class="mr-1 custom-button-color"
                >
                  Submit
                </b-button>
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  type="reset"
                  variant="outline-secondary"
                >
                  Clear
                </b-button>
              </div>
            </b-col>
          </b-row>
        </b-form>
      </ValidationObserver>
    </b-overlay>
  </b-modal>
</template>

<script>
import {
  BButton,
  BModal,
  BCol,
  BFormGroup,
  BForm,
  BFormInput,
  BRow,
  BOverlay,
  BFormCheckbox,
  VBModal,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import SuccessMessage from '@core/mixins/SuccessMessage'
import ErrorMessage from '@core/mixins/ErrorMessage'
import vSelect from 'vue-select'
import { RepositoryFactory } from '@/repository/RepositoryFactory'

const ResourceRepository = RepositoryFactory.get('resource')
const MerchantRepository = RepositoryFactory.get('merchant')

function initialState() {
  return {
    open: false,
    id: null,
    form: {},
    modalLoading: false,
    loading: false,
    cityOptions: [],
    stateOptions: [],
    countryOptions: [],
    addressTypeOptions: [
      { id: 'general', name: 'General' },
      { id: 'pickup', name: 'Pick-up' },
    ],
  }
}

export default {
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  components: {
    BButton,
    BModal,
    BCol,
    BFormGroup,
    BForm,
    BFormInput,
    BRow,
    BOverlay,
    BFormCheckbox,
    'v-select': vSelect,
  },
  mixins: [SuccessMessage, ErrorMessage],
  data() {
    return initialState()
  },
  methods: {
    async openModal(merchantId, businessId, addressId) {
      await Object.assign(this.$data, initialState())
      this.open = true
      this.merchantId = merchantId
      this.businessId = businessId
      this.addressId = addressId
      this.modalLoading = true
      await this.fetchMerchantBusinessAddressDetails()
      await this.fetchCountryList()
      await this.fetchStateList()
      await this.fetchCityList()
      this.modalLoading = false
    },
    async fetchCityList(value) {
      if (!this.modalLoading) {
        this.form.city = null
      }
      try {
        if (value !== '') {
          this.search = value
        }
        this.loading = true
        const { data } = (await ResourceRepository.getCityDropdownListNoPagination(this.form.state || '', this.search || '')).data
        this.cityOptions = data
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.loading = false
    },
    async fetchStateList() {
      if (!this.modalLoading) {
        this.form.state = null
        this.form.city = null
      }
      try {
        const { data } = (await ResourceRepository.getStateDropdownList(this.form.country)).data
        this.stateOptions = data
      } catch (e) {
        this.convertAndNotifyError(e)
      }
    },
    async fetchCountryList() {
      try {
        const { data } = (await ResourceRepository.getCountryList()).data
        this.countryOptions = data
      } catch (e) {
        this.convertAndNotifyError(e)
      }
    },
    async fetchMerchantBusinessAddressDetails() {
      try {
        const { data } = (await MerchantRepository.getMerchantAddressDetails(this.merchantId, this.businessId, this.addressId)).data
        this.form = {
          addressLine1: data.address_line_1,
          addressLine2: data.address_line_2,
          addressLine3: data.address_line_3,
          city: data.city_id,
          state: data.city.state_id,
          country: data.city.country_id,
          postalCode: data.postal_code,
          addressType: data.type,
          is_default: data.is_default,
          contactID: data.default_contact.id,
          contactNo: data.default_contact.phone,
          email: data.default_contact.email,
          isContactDefault: data.default_contact.is_default,
        }
      } catch (e) {
        this.convertAndNotifyError(e)
      }
    },

    async onClickUpdateMerchantBusinessAddress() {
      this.modalLoading = true
      try {
        const payload = {
          address_details: {
            address_line_1: this.form.addressLine1,
            address_line_2: this.form.addressLine2,
            address_line_3: this.form.addressLine3,
            city_id: this.form.city,
            state_id: this.form.state,
            phone: this.form.contactNo,
            country_id: this.form.country,
            postal_code: this.form.postalCode,
            type: this.form.addressType,
            is_default: this.form.is_default,
          },
        }
        const res = await MerchantRepository.updateMerchantAddress(this.merchantId, this.businessId, this.addressId, payload)
        if (res.status === 200) {
          const contactPayload = {
            contact_details: {
              phone: this.form.contactNo,
              email: this.form.email,
              is_default: true,
            },
          }
          const contactRes = await MerchantRepository.updateMerchantContact(this.merchantId, this.businessId, this.addressId, this.form.contactID, contactPayload)
          if (contactRes.status === 200) {
            this.showSuccessMessage('Merchant business address updated successfully')
            this.open = false
            this.$parent.onClickRefresh()
          } else {
            this.showErrorMessage('Sorry for the inconvenience! Please Try Again!!')
          }
        } else {
          this.showErrorMessage('Sorry for the inconvenience! Please Try Again!!')
        }
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.modalLoading = false
    },
  },
}
</script>
<style scoped>
@import "../../../node_modules/@syncfusion/ej2-base/styles/fabric.css";
@import "../../../node_modules/@syncfusion/ej2-vue-navigations/styles/fabric.css";
@import "../../../node_modules/@syncfusion/ej2-inputs/styles/fabric.css";
@import "../../../node_modules/@syncfusion/ej2-buttons/styles/fabric.css";
.custom-button-color {
  background-color: #4186f5 !important;
  border-color: #4186f5 !important;
}
</style>
<style lang="scss">
@import '@core/scss/vue/required.scss';
</style>
