<template>
  <b-card>
    <b-tabs ref="businessDetailManagementTabBar">
      <b-tab>
        <template #title>
          <span>General Details</span>
        </template>
        <MerchantBusinessGeneralDetailsManagement />
      </b-tab>
      <b-tab
        v-can="'view-merchant-address'"
      >
        <template #title>
          <span>Addresses & Contact Details</span>
        </template>
        <MerchantBusinessAddressManagement />
      </b-tab>
      <b-tab
        v-can="'view-merchant-bank'"
      >
        <template #title>
          <span>Bank Details</span>&nbsp;
        </template>
        <MerchantBusinessBankDetailsManagement />
      </b-tab>
      <b-tab
        v-can="'view-merchant-users'"
      >
        <template #title>
          <span>Users</span>
        </template>
        <MerchantBusinessUserManagement />
      </b-tab>
    </b-tabs>
  </b-card>
</template>

<script>
import {
  BTabs,
  BTab,
  BCard,
} from 'bootstrap-vue'
import MerchantBusinessAddressManagement from '@/views/merchant/MerchantBusinessAddressManagement.vue'
import MerchantBusinessBankDetailsManagement from '@/views/merchant/MerchantBusinessBankDetailsManagement.vue'
import MerchantBusinessUserManagement from '@/views/merchant/MerchantUserManagement.vue'
import MerchantBusinessGeneralDetailsManagement from '@/views/merchant/MerchantBusinessGeneralDetailsManagement.vue'

export default {
  components: {
    MerchantBusinessAddressManagement,
    MerchantBusinessBankDetailsManagement,
    MerchantBusinessUserManagement,
    MerchantBusinessGeneralDetailsManagement,
    BTabs,
    BTab,
    BCard,
  },
  data() {
    return {
    }
  },
  mounted() {
    // eslint-disable-next-line no-underscore-dangle
    this.__preserveTabOnRefresh('businessDetailManagementTabBar', 'businessDetailManagementTabIndex')
  },
}
</script>

<style>
</style>
